<template>
    <div class="contact">
        <h1>Contact Me</h1>
        <a href="mailto:someone@yoursite.com">brianfordcode@gmail.com</a>
        
       
    </div>   
  
</template>

<script>
export default {

}
</script>

<style scoped>

a {
    text-decoration: none;
    color: #2c3e50;
}

</style>